import React from "react"
import Marty from "../images/marty-icon.png"
import Resume from "../images/JohnLestockResume.pdf"
import Godzilla from "../images/ohNoGodzilla.png"
import Whiskey from "../images/allForMe.png"
import Frank from "../images/frankTheCat.png"

// import {
//   FaLinkedin as Linkedin,
// } from "react-icons/fa"

export default function info() {
  return (
    <div className="container">
      <div className="my-5"></div>
      <img src={Marty} alt="Marty"/>
      <br />
      <br />
      <div className="h1 code mt-4 mb-3" style={{ color: "#FF8A00" }}>
        John "Marty" Lestock.
      </div>
      <div className="h3 code mt-4 mb-3">object {"{"}</div>
      <div className="text-muted mt-4 mb-3 h5 text-justify info">
        <ul className="ulAbout text-justify info">
            <li><b>Name:</b> John "Marty" Lestock</li>
            <li><b>Profession:</b> Software Engineer / Web Developer</li>
            <li><b>Resume:</b> <a href={Resume}>Marty's Resume</a> </li>
            <li><b>Hobbies: </b><a href="https://www.youtube.com/watch?v=5NzniviPUL8" className="link">Music</a>, <a href={Godzilla} className="link">Art</a>, <a href={Whiskey} className="link">Whiskies</a>, 
            Ruebens, and <a href={Frank} className="link">Frank</a>.
            </li>
            <li><b>Message: </b><br/>
            Hi! I'm Marty, welcome to my website. Take a few minutes to look around.
            I've provided links to my projects, media, and some personal photos.
            Please feel free to reach out to me with any questions. </li>
        </ul>
      </div>
      <div className="h1 code mt-2 mb-3">{"}"}</div>
      <div className="h1 mt-5">
      </div>
    </div>
  )
}